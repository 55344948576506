import { render, staticRenderFns } from "./CustomerView.vue?vue&type=template&id=5d25a2a0&scoped=true&"
import script from "./CustomerView.vue?vue&type=script&lang=js&"
export * from "./CustomerView.vue?vue&type=script&lang=js&"
import style0 from "./CustomerView.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./CustomerView.vue?vue&type=style&index=1&id=5d25a2a0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d25a2a0",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinnerGears from 'quasar/src/components/spinner/QSpinnerGears.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QTabs,QTab,QTabPanels,QTabPanel,QChip,QSeparator,QAvatar,QTooltip,QInnerLoading,QSpinnerGears});
