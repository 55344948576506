<template>
  <div id="customer-structure" v-if="customerData" class="card p-2">
    <CustomerView ref="customerSidebarRef"/>
    <q-tree no-results-label=" " v-if="customerData" default-expand-all :nodes="customerData" labelKey="name" node-key="mlmSeq">
      <template #default-header="{node}">
        <div class="d-flex gap-2 align-items-center justify-content-between w-100">
          <div class="d-flex gap-2 align-items-center">
            <q-avatar :color="(node.mlmSeq===1||node.mlmSeq%2===0)?'primary':'danger'" text-color="white">{{node.level}}</q-avatar>

            <q-btn outline dense rounded color="primary">
              <div class="d-flex gap-2 align-items-center cursor-pointer mx-n75" @click.stop="customerSidebarRef.openSidebar(node._id)">
                <Avatar :url="node.files" defaultIcon="user" size="lg"/>
                {{node.name}}
                <fa-icon :icon="['far','chevron-right']" class="text-primary mr-50"/>
              </div>
            </q-btn>

            <q-chip size="sm" :color="`light-${getGrade(node.grade).color}`" text-color="white">
              {{getGrade(node.grade).names.find(n=>n.locale===$i18n.locale).name}}
            </q-chip>
          </div>

          <div class="d-flex gap-2">
            <!-- Personal / Group -->
            <q-badge outline color="secondary" class="font-weight-bold p-0" style="min-width:200px;min-height:40px">
              <div class="d-flex flex-column w-100 h-100">
                <div class="flex-grow-1 d-flex align-items-center justify-content-between px-50">
                  <fa-icon icon="user" class="text-warning" />
                  {{node.personal | price}}
                  <q-tooltip anchor="top middle" :offset="[0, 30]">{{$t('mlm.personal')|capitalize}}</q-tooltip>
                </div>
                <q-separator class="w-100" />
                <div class="flex-grow-1 d-flex gap-1 px-50">
                  <div v-if="node.group" class="w-100 d-flex gap-1 justify-content-between align-items-center"><fa-icon icon="users" class="text-primary" />{{node.group.l | price}}</div>
                  <q-separator vertical />
                  <div v-if="node.group" class="w-100 d-flex gap-1 justify-content-between align-items-center"><fa-icon icon="users" class="text-danger" />{{node.group.r | price}}</div>
                  <q-tooltip anchor="top middle" :offset="[0, 30]">{{$t('mlm.group')|capitalize}}</q-tooltip>
                </div>
              </div>
            </q-badge>

            <!-- Team -->
            <q-badge outline color="secondary" class="font-weight-bold p-0" style="min-width:200px;min-height:40px">
              <q-tooltip anchor="top middle" :offset="[0, 30]">{{$t('mlm.team')|capitalize}}</q-tooltip>
              <div v-if="node.team" class="d-flex gap-50 align-items-center w-100">
                <q-badge color="light-danger" class="text-vertical rounded-0">{{$t('mlm.team')|capitalize}}</q-badge>
                <div class="d-flex gap-1 flex-grow-1 px-25">
                  <div class="flex-even d-flex flex-column justify-content-between align-items-start gap-50">
                    <div class="w-100 d-flex gap-1 justify-content-between align-items-center"><q-badge color="primary" size="sm" text-color="white">{{node.level+1}}</q-badge>{{node.team.l | price}}</div>
                    <div class="w-100 d-flex gap-1 justify-content-between align-items-center"><q-badge color="primary" size="sm" text-color="white">{{node.level+2}}</q-badge>{{node.team.ll | price}}</div>
                  </div>
                  <q-separator vertical />
                  <div class="flex-even d-flex flex-column justify-content-between align-items-start gap-25">
                    <div class="w-100 d-flex gap-1 justify-content-between align-items-center"><q-badge color="danger" size="sm" text-color="white">{{node.level+1}}</q-badge>{{node.team.r | price}}</div>
                    <div class="w-100 d-flex gap-1 justify-content-between align-items-center"><q-badge color="danger" size="sm" text-color="white">{{node.level+2}}</q-badge>{{node.team.rl | price}}</div>
                  </div>
                </div>
              </div>
            </q-badge>
          </div>
        </div>
      </template>
    </q-tree>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { capitalize, price } from '@core/utils/filter'
import useCustomer from './useCustomer'
import CustomerView from './CustomerView.vue'
import Avatar from '@core/components/Avatar'

export default {
  name: 'customer-structure',
  components: {
    Avatar, CustomerView
  },
  filters: {
    capitalize, price
  },
  setup(){
    const customerSidebarRef = ref(null)
    const customerData = ref(null)
    const gradesData = ref(null)
    const { fetchCustomerTree } = useCustomer()

    const getGrade = (id)=>{
      if (!gradesData.value || gradesData.value.length===0) return {}
      const grades = JSON.parse(JSON.stringify(gradesData.value)).sort((a,b)=>a-b)      
      if (!id) return grades[0]
      return grades.find(g=>g._id===id) || [0]
    }

    fetchCustomerTree({}, ({customers, grades})=>{
      customerData.value = customers
      gradesData.value = grades
    })

    return {
      customerSidebarRef,
      getGrade,

      customerData,
      gradesData,
      fetchCustomerTree
    }
  }
}
</script>

<style lang="scss">
#customer-structure{
  .q-badge.no-padding{
    padding: 0
  }
}
</style>