<template>
<div>
  <component :is="isPage?'div':'BSidebar'" id="customer-view" right sidebar-class="sidebar-lg" shadow backdrop :width="sidebarWidth" header-class="p-0" bg-variant="white"
    :visible="isSidebarActive" @hidden="close()" class="rounded-lg overflow-hidden shadow-sm">
    <template v-slot:header>
      <div class="w-100">
        <div class="w-100 d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1 bg-white border-bottom shadow-sm z-index-10">
          <div v-if="customerData" class="flex gap-3 align-items-center text-primary">
            <fa-icon :icon="['fad', $t('member.customer.icon')]" size="2x"/>
            <div class="flex flex-column">
              <span>{{$t('member.customer._') | capitalize}}</span>
              <span class="m-0 h5">{{customerData.name}}</span>
            </div>
            <q-btn flat padding="5px" :to="{name: 'member-customer-view', params:{customerId: customerData._id}}"><fa-icon icon="expand"/></q-btn>
          </div>
          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="close"/>
        </div>
        <q-tabs v-model="tab" class="bg-light-secondary inner-shadow" active-color="primary" indicator-color="primary" align="justify" narrow-indicator>
          <q-tab name="details" icon="info" :label="$t('form.details').toUpperCase()" class="pt-50 " content-class="gap-50 mb-25"/>
          <q-tab name="sales" icon="store" :label="$t('supply-chain.sale.sales').toUpperCase()" class="pt-50 " content-class="gap-50 mb-25"/>
          <q-tab name="transactions" icon="attach_money" :label="$t('finance.transaction.transactions').toUpperCase()" class="pt-50 " content-class="gap-50 mb-25"/>
        </q-tabs>
      </div>
    </template>
    
    <q-tabs v-if="isPage" v-model="tab" class="bg-light-secondary" active-color="primary" indicator-color="primary" align="justify" narrow-indicator>
      <q-tab name="details" icon="info" :label="$t('form.details').toUpperCase()" class="pt-50 " content-class="gap-50 mb-25"/>
      <q-tab name="sales" icon="store" :label="$t('supply-chain.sale.sales').toUpperCase()" class="pt-50 " content-class="gap-50 mb-25"/>
      <q-tab name="transactions" icon="attach_money" :label="$t('finance.transaction.transactions').toUpperCase()" class="pt-50 " content-class="gap-50 mb-25"/>
    </q-tabs>
    <q-tab-panels v-model="tab" value="order" animated transition-prev="jump-left" transition-next="jump-right" class="h-100">
      <q-tab-panel name="details" class="p-0 bg-white">
        <div v-if="customerData">
          <Carousel :files="customerData.files" />
          <div class="shadow-top-sm p-2 flex flex-column gap-2">
            <!-- User -->
            <div class="flex-even d-flex gap-2">
              <fa-icon :icon="['fad','user']" fixed-width class="mt-50 text-primary text-size-150"/>
              <div>
                <label class="text-primary d-block m-0 text-capitalize">{{$t('form.name')}}</label>
                <div v-if="customerData.name" class="text-capitalize">{{customerData.name }}</div>
                <div v-if="customerData.phone"><q-chip dense :label="customerData.country"/> {{customerData.phone}}</div>
                <div v-if="customerData.email">{{customerData.email}}</div>
              </div>
            </div>
            
            <!-- Tags -->
            <div class="flex-even flex-size-4 flex gap-2">
              <fa-icon :icon="['fad','tags']" fixed-width class="mt-50 text-primary text-size-150"/>
              <div>
                <label class="text-primary d-block text-capitalize">{{$t('form.tags')}}</label>
                <span class="d-flex flex-wrap gap-1">
                  <q-chip v-for="(tag, index) in customerData.tags" color="light-primary" :key="tag.index" dense >{{tag}}</q-chip>
                  <q-btn flat dense @click.stop="updateTags"><fa-icon class="text-danger mx-n1" icon="pen"/></q-btn>
                </span>
              </div>
            </div>
          </div>

          <!-- 地址 -->
          <h5 v-if="customerData.addresses && customerData.addresses.length>0" class="text-primary bg-light-secondary m-0 px-2 py-75 d-flex gap-2 align-items-center">
            <span class="flex gap-2 align-items-center text-capitalize">
              <fa-icon :icon="['fad','map']" fixed-width />
              {{$t('action.address')}}
            </span>
          </h5>
          <div v-for="address in customerData.addresses" :key="address._id" class="rounded-lg shadow-sm p-1 mx-2 my-1 bg-light-primary border-primary d-flex flex-column flex-sm-row gap-2">
            <div>
              <div class="d-flex justify-content-between align-items-center gap-2">
                <h5 class="m-0">{{address.name}}</h5>
              </div>
              <span>{{address.country}} {{address.phone}}</span>
            </div>
            <q-separator vertical class="d-none d-sm-inline" />
            <q-separator class="d-sm-none" />
            <div>
              <div>{{address.unit}}</div>
              <div class="text-wrap">{{address.address}}</div>
              <div>{{address.city}} {{address.zip}}</div>
            </div>
          </div>
        </div>
      </q-tab-panel>

<!-- Sales -->
      <q-tab-panel name="sales" class="p-0">
        <div v-if="customerData" class="d-flex flex-column gap-3 p-2">
          <BTable class="position-relative text-center rounded-lg shadow mb-0" responsive show-empty hover primary-key="_id" sort-by="createdAt" sort-desc
            :items="customerData.sales" :fields="[
              { key: 'orderNo', label:$t('form.order_number'), sortable: true },
              { key: 'total', label:$t('form.amount'), isNumber:true, sortable: true},
              { key: 'variants', label:$t('form.variants'), noFilter: true},
              { key: 'tags', label:$t('form.tags')},
              { key: 'createdAt', label:$t('form.date')}]">
            <template #empty>
              <fa-icon class="my-3 text-secondary" :icon="['fad','cauldron']" size="4x" />
              <h5>{{$t("table.noResult")}}</h5>
            </template>

            <!-- Order number -->
            <template #cell(orderNo)="{ item }">
              <div @click.stop="saleSidebarRef.open(item._id)" class="d-flex gap-1 w-100 justify-content-between align-items-center">
                <div class="d-flex w-100 flex-column gap-50 align-items-start">
                  <div class="d-flex gap-1 align-items-center" :class="item.deleted?'text-danger':'text-primary'">{{ item.orderNo }}</div>
                  <!-- Status -->
                  <q-chip v-if="item.status" size="sm" :color="`light-${$t('supply-chain.status.'+item.status+'.color')}`">
                    <q-avatar :color="$t('supply-chain.status.'+item.status+'.color')" text-color="white">
                      <fa-icon fixed-width :icon="['far',$t('supply-chain.status.'+item.status+'.icon')]" />
                    </q-avatar>
                    <span class="text-capitalize text-nowrap d-none d-sm-inline">{{$t(`supply-chain.status.${item.status}._`)}}</span>
                  </q-chip>
                </div>
                <fa-icon :icon="['far','chevron-right']" class="text-primary ml-1 mr-50"/>
              </div>
            </template>

            <!-- Total amount -->
            <template #cell(total)="{ item }">
              <div class="d-flex flex-column gap-50">
                <div class="text-nowrap" :class="item.total<0?'text-danger':'text-primary'">
                  {{item.total||0 | price($t(`currency.${item.currency}.sign`))}} 
                  <q-avatar size="xs" :color="item.total===item.paidAmount?'success':(item.total>item.paidAmount?'warning':'danger')" text-color="white">
                    <fa-icon fixed-width :icon="['far',item.total===item.paidAmount?'check':(item.total>item.paidAmount?'circle':'times')]" />
                  </q-avatar>
                </div>
                <div class="d-flex gap-1">
                  <q-btn size="sm" :to="{name:'sale-invoice-view', params:{id:item._id} }" push color="primary">{{$t('supply-chain.invoice._')}}</q-btn>
                  <q-btn size="sm" v-if="item.paidAmount<item.total" push color="warning"
                    @click.stop="payDialogRef.openDialog({saleId:item._id, customerId:item.customer._id}).then(v=>v===true?refetchData():'')">{{$t('finance.action.pay')}}</q-btn>
                </div>
              </div>
            </template>

            <!-- Variants -->
            <template #cell(variants)="{ item }">
              <div v-for="item in item.variants.filter(t=>!t.isPayment)" :key="item._id" class="d-flex gap-1 justify-content-between align-items-center">
                <div class="d-flex gap-1 align-items-center flex-nowarp">
                  <q-chip size="sm" :color="item.isInventory?'light-primary':'light-danger'" class="flex-nowrap">
                    <q-avatar>
                      <img :src="fileUrl(item.image, 'variant')"/>
                      <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
                        {{item.productNames | name}} - {{item.names | name}}
                      </q-tooltip>
                    </q-avatar>
                    {{item.productNames | name}} - {{item.names | name}}  
                  </q-chip>
                </div>
                <span class="text-primary text-nowrap">x {{item.quantity}}</span>
              </div>
            </template>

            <!-- Tags -->
            <template #cell(tags)="{ item }">
              <div class="d-flex flex-wrap gap-1">
                <q-chip v-for="(tag, index) in item.tags" color="light-primary" :key="tag.index" dense >{{tag}}</q-chip>
              </div>
            </template>
          
            <!-- createdAt -->
            <template #cell(createdAt)="{ value }">
              {{value | date}}
            </template>
          </BTable>
        </div>
      </q-tab-panel>

<!-- customer transactions -->
      <q-tab-panel name="transactions" class="p-0">
        <div class="d-flex flex-column gap-3 p-2">
          <VChart ref="chartRef" class="chart" :option="chartOption" autoresize/>
          <BTable class="position-relative text-center rounded-lg shadow mb-0" responsive show-empty hover primary-key="_id" sort-by="createdAt" sort-desc
            :items="customerData.customerTrans" :fields="[
              {key:'contributor',label:$t('mlm.contributor')},
              {key:'details',label:$t('form.details')},
              {key:'balance.c',label:$t('mlm.balance')}, 
              {key:'credit.c',label:$t('mlm.credit')},
              {key:'pension.c',label:$t('mlm.pension')},
              {key:'createdAt',label:$t('form.date')}]">
            <template #empty>
              <fa-icon class="my-3 text-secondary" :icon="['fad','cauldron']" size="4x" />
              <h5>{{$t("table.noResult")}}</h5>
            </template>
            <!-- contributor -->
            <template #cell(contributor)="{ item }">
              <div v-if="item.type==='reward'" class="d-flex justify-content-between align-items-center" @click.stop="open(item.contributor._id)">
                <q-chip size="sm" color="light-primary"><fa-icon icon="user" class="mr-50"/>{{item.contributor.name }}</q-chip>
                <fa-icon icon="chevron-right" class="mx-50 text-primary"/>
              </div>
              <fa-icon v-else-if="item.type==='upgrade'" :icon="['fad','angle-double-up']" class="ml-50 text-success"/>
              <fa-icon v-else-if="item.type==='payment'" :icon="['fad','shopping-cart']" class="ml-50 text-danger"/>
            </template>
            <!-- details -->
            <template #cell(details)="{ item }">
              <div class="d-flex justify-content-between align-items-center" @click.stop="saleSidebarRef.open(item.sale)">
                <q-chip size="sm" color="primary" text-color="white"><fa-icon icon="shopping-cart" class="mr-50"/>{{item.orderNo}}</q-chip>
                <fa-icon icon="chevron-right" class="mx-50 text-primary"/>
              </div>
            </template>
            <!-- createdAt -->
            <template #cell(createdAt)="{ value }">
              {{value | date}}
            </template>
          </BTable>
        </div>
      </q-tab-panel>
    </q-tab-panels>
    <FieldDialog ref="fieldDialogRef" />
    <q-inner-loading :showing="loading">
      <q-spinner-gears size="50px" color="primary" />
    </q-inner-loading>
  </component>
  <SaleView ref="saleSidebarRef"/>
</div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import { useRouter } from '@core/utils/utils'
import { BSidebar, BTable } from 'bootstrap-vue'
import { Dialog } from '@core/utils/other'
import { i18n, fileUrl, date, capitalize, price, name } from '@core/utils/filter'
import useCustomer from './useCustomer'
import Carousel from '@core/components/Carousel'
import FileGallery from '@core/components/file-gallery/FileGallery'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import FieldDialog from '@core/components/FieldDialog'

import SaleView from '../../supply-chain/sale/SaleView'

//EChart
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers"
import { LineChart } from "echarts/charts"
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components"
import VChart, { THEME_KEY } from "vue-echarts"

use([
  CanvasRenderer,
  TitleComponent,
  LegendComponent,
  TooltipComponent,
  GridComponent,
  LineChart
])

export default {
  name: 'customer-view',
  components: {
    BSidebar, BTable,
    Carousel, FileGallery, FieldDialog,
    AppCollapse, AppCollapseItem,
    SaleView,
    VChart
  },
  props:{
    isPage: Boolean
  },
  provide: {
    [THEME_KEY]: "light"
  },
  filters: {
    date, capitalize, price, name
  },
  setup(props){
    const { route, router } = useRouter()
    const saleSidebarRef = ref(null)
    const sidebarWidth = ref('600px')
    const {
      loading,
      customerData,
      fetchCustomer,
    } = useCustomer()

    const chartRef = ref(null)
    const refreshChart = ref(false)
    const chartOption = {
      tooltip: { trigger: 'axis' },
      legend: { data: [i18n('mlm.balance'), i18n('mlm.credit'), i18n('mlm.pension')] },
      grid: { left: '3%', right: '4%', bottom: '3%', containLabel: true },
      xAxis: { type: 'category', boundaryGap: false, data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'] },
      yAxis: { type: 'value' },
      series: [{
        name: i18n('mlm.balance'), type: 'line', data: [0,0,0,0,0,0] }, {
        name: i18n('mlm.credit'), type: 'line', data: [0,0,0,0,0,0] }, {
        name: i18n('mlm.pension'), type: 'line', data: [0,0,0,0,0,0] 
      }]
    }

    const fetchCustomerCallback = r =>{
      loading.value = false
      // series
      if (r.series){
        chartRef.value
        chartOption.xAxis = { type: 'category', boundaryGap: false,
          data: r.series.x.map(d=>new Date(d).toLocaleDateString('zh',{year: "numeric", month: "2-digit", day: "2-digit"}).replace(/\//g, '-'))
        }
        chartOption.series = [
          { name: i18n('mlm.balance'), type: 'line', smooth: true, data: r.series.balance }, 
          { name: i18n('mlm.credit'), type: 'line', smooth: true, data: r.series.credit }, 
          { name: i18n('mlm.pension'), type: 'line', smooth: true, data: r.series.pension }
        ]
      }
      refreshChart.value = true
    }

    // Page view
    if (props.isPage) {
      if (route.value.params.customerId) {
        loading.value = true
        fetchCustomer(route.value.params.customerId, fetchCustomerCallback)
      } else {
        router.go(-1)
      }
    }
    
    // Sidebar view
    const isSidebarActive = ref(false)
    let isUpdated = false
    let closeAction = () => isSidebarActive.value = false
    const close = () => closeAction()
    const open = async id => {
      return new Promise(res=>{
        if(!id) return res(false)
        loading.value = true
        isSidebarActive.value = true
        fetchCustomer(id, ()=> {
          loading.value = false
          closeAction = () => {
            res(isUpdated)
            isSidebarActive.value = false
          }
        })
      })
    }

    // 更新 Chart 视图
    watch([chartRef, refreshChart], ()=>{
      if (chartRef.value && refreshChart.value===true) {
        chartRef.value.setOption(chartOption)
        refreshChart.value = false
      }
    })

    // Tabs
    const tab = ref('details')
    watch(tab, val=>{
      if (val==='details') sidebarWidth.value = "600px"
      if (val==='sales') sidebarWidth.value = '800px'
      if (val==='transactions') sidebarWidth.value = '800px'  
    })

    // Update tags
    const fieldDialogRef = ref(null)
    const updateTags = async () =>{
      const newTags = await fieldDialogRef.value.openDialog({data:customerData.value.tags, label:i18n('form.tags'), icon: ['fad','tags']})
      if (newTags === false) return
      loading.value = true
      store.dispatch('customer/updateTags', {customerId: customerData.value._id, tags:newTags}).then(()=>fetchCustomer(customerData.value._id, ()=>{
        isUpdated = true
        fetchCustomerCallback
      })).catch(e=>{
        loading.value = false
        Dialog(false).fire({
          icon: 'error',
          title: e.message
        })
      })
    }

    return {
      loading,
      capitalize, fileUrl,
      customerData,
      saleSidebarRef,

      // Sidebar
      isSidebarActive,
      sidebarWidth, 
      open,
      close,
      
      // tabs
      tab,
      

      // Tags
      fieldDialogRef,
      updateTags,
      
      // Echart
      chartRef,
      chartOption
    }
  }
}
</script>

<style lang="scss">
#customer-view{
  transition: all 300ms;
  
  //Hide scrollbar
  ::-webkit-scrollbar {
    display: none;
  }
  .b-sidebar-body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .text-size-150 {
    font-size: 1.5rem
  }
  .z-index-10 {
    z-index: 10
  }
  .journal {
    table {
      td {
        padding: 0.25rem 1rem;
      }
      tr:first-child {
        td {
          padding-top: 0.5rem;
        }
      }
      tr:last-child {
        td {
          padding-bottom: 0.5rem;
        }
      }
    }
  }

  .reward {
    table {
      th {
        padding: 0.72rem 1.25rem;
      }

      td:nth-child(3){
        border-left: rgba(var(--secondary)) solid 1px;
      }

      td:nth-child(5){
        border-right: rgba(var(--secondary)) solid 1px;
      }
    }
  }
}
</style>

<style scoped>
.chart {
  height: 300px;
}
</style>