import { ref } from '@vue/composition-api'
import store from '@/store'
import $global from '@/globalVariables'
import customerStoreModule from './customerStoreModule'
if (!store.hasModule('customer')) store.registerModule('customer', customerStoreModule)
import { swal, Dialog } from '@core/utils/other'
import { i18n } from '@core/utils/filter'

export default function useCustomerList(props, emit) {
  const loading = ref(false)
  const emptyCustomer = ()=>({
    country: $global.default_country_code,
    mask: $global.default_phone_mask,
    city: $global.default_city,
    tags:[],
    addresses:[]
  })

  const customerData =  ref(emptyCustomer())
  const resetCustomer = () => customerData.value = emptyCustomer()

  const fetchCustomerTree = ({id}, callback) => {
    store.dispatch('customer/fetchCustomerTree', id)
    .then(response => {
      const { customers, grades } = response.data
      callback({customers, grades})
    }).catch(() => {
      Dialog(false).fire({ icon: 'error', title: i18n('rule.not_available') })
    })
  }

// Fetch a customer
  const fetchCustomer = (id, callback) => {
    if (!id) return
    return store.dispatch('customer/fetchCustomer', id).then(response => {
      customerData.value = response.customer
      if (callback) callback(response)
    }).catch(() => customerData.value = null )
  }

  const deleteCustomer = async id =>{
    const v = await swal.fire({
      icon: 'warning',
      title: i18n('message.if_confirm_delete'),
      showCancelButton: true,
      customClass: {
        confirmButton: 'btn btn-danger rounded-lg text-capitalize',
        cancelButton: 'btn btn-outline-primary rounded-lg ml-1 text-capitalize',
      },
      confirmButtonText: i18n('action.delete')
    })
    if (v.value) return store.dispatch('customer/deleteCustomer', customerId)
  }

  return {
    loading,
    customerData,
    resetCustomer,
    fetchCustomer,
    fetchCustomerTree,
    deleteCustomer
  }
}
